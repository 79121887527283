import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { lazy, Suspense, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ProtectedRoutes from './components/ProtectedRoute';
import ScrollToTop from './utils/ScrollToTop';
import { Spin } from 'antd';
import { Logo } from './assets';

const Home = lazy(() => import('./components/Pages/Home/index'));
const About = lazy(() => import('./components/Pages/About/index'));
const Contact = lazy(() => import('./components/Pages/Contact/index'));
const Color = lazy(() => import('./components/Pages/Color/index'));
const Product = lazy(() => import('./components/Pages/Product/index'));
const Quote = lazy(() => import('./components/Pages/Quote/index'));

const Dashboard = lazy(() => import('./components/ProtectedRoute/Pages/Dashboard/index'))
const Profile = lazy(() => import('./components/ProtectedRoute/Pages/Profile/index'))
const Settings = lazy(() => import('./components/ProtectedRoute/Pages/Settings/index'))

function App() {
  const isAuthenticated = false;

  const [delayed, setDelayed] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayed(false);
    }, 2000); // 3 seconds delay

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  return (
    <Router>
      <Suspense fallback={<div></div>}>
      {delayed ? (
          <div className='w-full h-full absolute bg-white bg-opacity-75 flex flex-col justify-center items-center'>
            <Spin size='large' />
            <img src={Logo} alt='Logo' />
            <div>PREMIUM QUALITY PAINT</div>
          </div>
        ) : (
          <>
        <ScrollToTop />
        <AnimatePresence>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/color-chart" element={<Color />} />
            <Route path="/product" element={<Product />} />
            <Route path="/quote" element={<Quote />} />
            <Route path='/dashboard' element={
              <ProtectedRoutes isAuthenticated={isAuthenticated}>
                <PageTransition>
                  <Dashboard />
                </PageTransition>
              </ProtectedRoutes>
            } />
            <Route path='/dashboard/profile' element={<Profile />} />
            <Route path='/dashboard/settings' element={<Settings />} />
          </Routes>
        </AnimatePresence>
      </>
        )}
      </Suspense>
    </Router>
  );
}

function PageTransition({children}){
  return(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
        {children}
      </motion.div>
  );
}



export default App;
